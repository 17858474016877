import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/Sidebar";
import { useSelector } from "react-redux";
// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import SignIn from "views/auth/SignIn";
import UserAdd from "views/admin/user/UserAdd";
import User from "views/admin/user/User";
import UserEdit from "views/admin/user/UserEdit";
import UserDelete from "views/admin/user/UserDelete";

import CarriersForm from "views/carriers/CarriersForm";
import Carriers from "views/carriers/Carriers";
import CarriersFormEdit from "views/carriers/CarrierFormEdit";
import CarrierDelete from "views/carriers/DeleteCarrier";


import InvoiceCarrier from "views/invoices/InvoiceCarrier";
import InvoicesForm from "views/invoices/InvoicesForm";
import Invoices from "views/invoices/Invoices";
import ManagePicksDrops from "views/invoices/ManagePicksDrops";


// Icon Imports
import { MdHome, MdPerson } from "react-icons/md";
import { ImBlog } from "react-icons/im";
import { FaUserFriends } from "react-icons/fa";
import { AiOutlineLogout } from "react-icons/ai";

import { useNavigate } from "react-router-dom";

import SignOut from "views/auth/SignOut";

import { getUserDataByToken } from "proxyUrl";
import ErrorPaheInner from "views/ErrorPaheInner";
import axios from "axios";
import InvoicesFormEdit from "views/invoices/InvoiceFormEdit";
import InvoiceDelete from "views/invoices/DeleteInvoice";

export default function Admin(props) {
  
  const navigate = useNavigate();
  const [permission, setPermission] = useState();

  const userDataByApi = async () => {
    await axios
      .get(getUserDataByToken, {
        withCredentials: true,
      })
      .then((res) => {
        setPermission(res.data.user.userPermission)
        return res;
      }).catch(()=>{
        navigate("/auth/sign-in");
      });
  };

  const routesLinks = [
    {
      name: "Dashboard",
      layout: `/admin`,
      path: "dashboard",
      icon: <MdHome className="h-6 w-6" />,
      display: "",
      component: <MainDashboard />,
    },
  
  ];
  useEffect(() => { 
    
    userDataByApi();
   
  }, []);
  // generate routes ***************************
  
  const logged_user_info = useSelector((state) => state.user.user);
  if (!logged_user_info.userPermission) {
  }
  const userPermissions = logged_user_info.userPermission
    ? logged_user_info.userPermission
    : permission;
  if (userPermissions) {
    userPermissions.forEach((element) => {
      if (element === "userAdd") {
        routesLinks.push(
          {
            name: "Users",
            layout: `/${`admin`}`,
            icon: <FaUserFriends className="h-6 w-6" />,
            path: "users",
            display: "",
            component: <User />,
          },
          {
            name: "add user",
            layout: `/${`admin`}`,
            path: "users/add",
            display: "hidden",
            icon: "",
            component: <UserAdd />,
          }
        );
      } else if (element === "userEdit") {
        routesLinks.push({
          name: "edit user",
          layout: `/${`admin`}`,
          path: "users/edit/:id",
          display: "hidden",
          icon: "",
          component: <UserEdit />,
        });
      } else if (element === "userDelete") {
        routesLinks.push({
          name: "edit user",
          layout: `/${`admin`}`,
          path: "users/delete/:id",
          display: "hidden",
          icon: "",
          component: <UserDelete />,
        });
      } else if (element === "carrier") {
        routesLinks.push(
          {
            name: "Carriers",
            layout: `/${`admin`}`,
            path: "Carriers",
            display: "",
            icon: <ImBlog className="h-6 w-6" />,
            component: <Carriers />,
          },
          {
            name: "CarrierAdd",
            layout: `/${`admin`}`,
            path: "carriers/add",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <CarriersForm />,
          },
          {
            name: "CarrierEdit",
            layout: `/${`admin`}`,
            path: "carriers/edit/:id",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <CarriersFormEdit />,
          },
          {
            name: "CarrierDelete",
            layout: `/${`admin`}`,
            path: "carriers/delete/:id",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <CarrierDelete />,
          }
        );
      }else if (element === "invoice") {
        routesLinks.push(
          {
            name: "Invoices",
            layout: `/${`admin`}`,
            path: "Invoices",
            display: "",
            icon: <ImBlog className="h-6 w-6" />,
            component: <Invoices />,
          },
          {
            name: "InvoicesAdd",
            layout: `/${`admin`}`,
            path: "invoices/add",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <InvoicesForm />,
          },
          {
            name: "InvoiceCarrier",
            layout: `/${`admin`}`,
            path: "invoices/carrier_invoice/:id",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <InvoiceCarrier />,
          },
          {
            name: "InvoiceEdit",
            layout: `/${`admin`}`,
            path: "invoices/edit/:id",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <InvoicesFormEdit />,
          },
          {
            name: "InvoiceDelete",
            layout: `/${`admin`}`,
            path: "invoices/delete/:id",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <InvoiceDelete />,
          },
          {
            name: "managepicks",
            layout: `/${`admin`}`,
            path: "invoices/managepicks/:id",
            display: "hidden",
            icon: <ImBlog className="h-6 w-6" />,
            component: <ManagePicksDrops />,
          }
        );
      }
    });
   }
    routesLinks.push(
      {
        name: "Profile",
        layout: `/${`admin`}`,
        path: "profile",
        display: "hidden",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Profile />,
      },
      {
        name: "Log In",
        layout: "/auth",
        path: "sign-in",
        display: "hidden",
        icon: <AiOutlineLogout className="h-6 w-6" />,
        component: <SignIn />,
      },
      {
        name: "Log Out",
        layout: "/auth",
        path: "sign-out",
        display: "",
        icon: <AiOutlineLogout className="h-6 w-6" />,
        component: <SignOut />,
      }
    );

  // generate routes ***************************
  const { ...rest } = props;
  const [open, setOpen] = useState(true);

  const [currentRoute, setCurrentRoute] = useState("Dashboard");

  useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);

 

  const getActiveRoute = (routes) => {
    let activeRoute = "Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  useEffect(() =>{
    getActiveRoute(routesLinks);
  })

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full bg-gray-200  ">
      {/* <div className="w-full h-9 z-30 bg-navy-600 fixed"></div> */}
      <Sidebar
        open={open}
        routes={routesLinks}
        onClose={() => setOpen(false)}
      />
      {/* Navbar & Main Content */}
      <div className="h-full w-full  dark:!bg-navy-900 pb-8">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={" Value Dispatcher"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routesLinks)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {routesLinks.map((prop, key) => {
                  return (
                    <Route
                      path={`/${prop.path}`}
                      element={prop.component}
                      key={key}
                    />
                  );
                })}
                <Route
                  path="/auth/sign-in"
                  element={<navigate to="/admin/Dashboard" replace />}
                />
                 <Route
                  path="/*"
                  element={<ErrorPaheInner/>}
                />
              </Routes>
            </div>
            {/* <div className="p-3">
              <Footer />
            </div> */}
          </div>
        </main>
      </div>
    </div>
  );
}
