import React, { useState, useEffect } from "react";
import { Page, Document, View, StyleSheet, Text } from '@react-pdf/renderer';
import "./InvoicesForm.css";
import "react-toastify/dist/ReactToastify.css";
import { GetInvoicesDataById,GetPicksByInvoiceId,GetDropsByInvoiceId } from "proxyUrl";
import axios from "axios";
import { useParams } from "react-router-dom";

const styles = StyleSheet.create({
	page: {
		fontFamily: 'Helvetica',
		fontSize: 12,
		padding: 30,
	},
	title: {
		fontSize: 24,
		marginBottom: 30,
	},
	content: {
		marginBottom: 10,
	},
});

// const Invoice = () => (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <Text style={styles.title}>Invoice</Text>
//       <Text style={styles.content}>Invoice content goes here...</Text>
//     </Page>
//   </Document>
// );
const InvoiceInvoicePdfGenerator = ({setinvoiced}) => {
	const { id } = useParams();
	const date  = new Date;
	const [invoicePicksFromApi, setInvoicePicksFromApi] = useState([]);
	const [invoiceDropsFromApi, setInvoiceDropsFromApi] = useState([]);
	const [user, setUser] = useState({
		companyName: "",
		invoice_no: "",
		nscNo: "",
		mcNo: "",
		dotNo: "",
		dispatcher: "",
		address: "",
		state: "",
		city: "",
		zipcode: "",
		phoneNo: "",
		email: "",
		payment_mode: "USD",
		amount: "",
		invoiced:''
	});

	useEffect(() => {
		axios
			.get(`${GetInvoicesDataById}${id}`, {
				withCredentials: true,
			})
			.then((data) => {
				const invoice = data.data;
				setUser({
					...user,
					companyName: invoice.companyName ?? "",
					invoice_no: invoice.invoice_no ?? "",
					nscNo: invoice.nscNo ?? "",
					mcNo: invoice.mcNo ?? "",
					dotNo: invoice.dotNo ?? "",
					dispatcher: invoice.dispatcher ?? "",
					address: invoice.address ?? "",
					state: invoice.state ?? "",
					city: invoice.city ?? "",
					zipcode: invoice.zipcode ?? "",
					phoneNo: invoice.phoneNo ?? "",
					email: invoice.email ?? "",
					payment_mode: invoice.payment_mode?? "USD",
					amount: invoice.amount ?? "",
					equipment:invoice.equipment??'',
					size:invoice.size??'',
					pieces:invoice.pieces??'',
					commodity:invoice.commodity??'',
					miles:invoice.miles??'',
					weight:invoice.weight??'',
					min:invoice.min??'',
					max:invoice.max??'',
					invoiced:invoice.invoiced??''
				});
				setInvoicePicksFromApi(invoice?.picks??[]);
				setInvoiceDropsFromApi(invoice?.drops??[]);
				setinvoiced(invoice?.invoiced);
			});
	},[]);


	const tork = false;

	const [setUserPlace] = useState({
		companyName: "Enter Invoice Company Name",
		nscNo: "Enter Invoice NSC No",
		mcNo: "Enter Invoice MC No",
		dotNo: "Enter Invoice DOT No",
		dispatcher: "Enter Dispatcher Name",
		address: "Enter Invoice Address",
		state: "Enter Invoice state",
		city: "Enter Invoice city",
		zipcode: "Enter Invoice zipcode",
		phoneNo: "Type Invoice Phone",
		email: "Enter Invoice Email",
		amount: "Enter Invoice Amount",
	});

	if (tork === true) {
		setUserPlace(null);
	}

	return (
		<>
			<Document>
				<Page size="A4" style={styles.page}>
					<div>
						<View style={styles.section}>
							<Text style={styles.title}>Invoice</Text>
						</View>
						<View style={styles.section}>
							<Text style={styles.content}></Text>
							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0" }}>
								<tbody>
									<tr>
										<td rowspan="4" style={{ "textAlign": "center" }}>
											<img
												className="h-16 w-[100px] rounded-md object-cover"
												style={{ "width": "120px" }}
												src='/logo.png'
												alt=""
											/>

										</td>
										<td rowspan="4"><p class="bold" style={{ "fontWeight": "900", "fontSize": "14px", "margin": "0", "fontFamily": "'Open Sans', sans-serif" }}>Value Dispatcher<br /> 1887 WHITNEY MESA DR #6988,<br/> HENDERSON, NV89014</p>
										</td>
									</tr>
									<tr>
										<td style={{ "textAlign": "right" }}>
											{/* <h2 class="boldheading" style={{ "fontSize": "27px", "margin": "0", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>Invoice Rate Confirmation</h2> */}
										</td>
									</tr>
									<tr>
										<td style={{ "textAlign": "right" }}><h2 class="smallheading" style={{ "fontSize": "24px", "margin": "0", "padding": "0", "fontFamily": "'Open Sans', sans-serif", "marginBottom": "0" }}>Invoice #{user.invoice_no}   </h2>
										</td>
									</tr>
									<tr>

										<td style={{ "textAlign": "right" }}><p class="comontxt" style={{ "fontFamily": "'Open Sans', sans-serif", "margin": "0" }}>{date.getMonth()+1}/{date.getDate()}/{date.getFullYear()}(EST)</p>
										</td>
									</tr>
								</tbody>
							</table>
							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>
								<tbody>
									<tr>
										<td style={{ "height": "20px" }}>

										</td>
									</tr>
								</tbody>
							</table>
							<table cellspacing="0" cellpadding="0" border="1px" class="center_table" style={{ border:"1px solid black","maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>
								<tbody>
									{/* <tr>
										<td style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }} class="bold">Company Name</td>
										<td style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }} class="semi">NSC|MC|DOT Number</td>
										<td style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }} class="semi">Dispatcher</td>
									</tr> */}
									<tr>
										<td  style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }}>Company: {user.companyName}</td>
										<td  style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }}>NSC: {user.nscNo}</td>
										<td  style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }} >Dispatcher: {user.dispatcher}</td>
										
									</tr>
									<tr>
										<td style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }} >Address: {user.address}, {user.city}, {user.state} <br/>{user.zipcode} </td>
										<td style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }} >MC:  {user.mcNo}</td>
										<td style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }} >Email: {user.email}</td>
										
									</tr>
									<tr>
										<td style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }}>Phone: {user.phoneNo}</td>
										<td style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }}>DOT:  {user.dotNo}</td>
										<td style={{ "borderLeft": "1px solid black", "borderTop": "1px solid black", "padding": "2px 5px", "fontSize": "13px" }}>Fax: </td>
									</tr>
								</tbody>
							</table>
							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>
								<tbody>
									<tr>
										<td style={{ "height": "20px" }}>

										</td>
									</tr>
								</tbody>
							</table>
							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>
								<tbody>
									<tr>
										<td class="semibold" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>Size:  {user.size}
										</td>
										<td class="semibold" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>Equipment:  {user.equipment}
										</td>
										
										<td class="semibold" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>Pieces:   {user.pieces}
										</td>
									</tr>
									<tr>
										<td class="semibold" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>Commodity:  {user.commodity}
										</td>
										<td class="semibold" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>Miles:  {user.miles}
										</td>
										<td class="semibold" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>Weight:  {user.weight}
										</td>
									</tr>

									<tr>
										<td class="semibold" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>Temp Range
										</td>
										<td class="semibold" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>Min Temp:  {user.min}
										</td>
										<td class="semibold" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>Max Temp:  {user.max}
										</td>
									</tr>

								</tbody>
							</table>
							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>
								<tbody>
									<tr>
										<td style={{ "height": "20px" }}>

										</td>
									</tr>
								</tbody>
							</table>
							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>
								<tbody>
									<tr>
										<td class="darkhead" style={{ "borderRight": "1px solid #ffffff", "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px", "textAlign": "center", "background": "#4d4848", "color": "#ffffff" }}>Charges
										</td>
										<td colspan="2" class="darkhead" style={{ "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px", "textAlign": "center", "background": "#4d4848", "color": "#ffffff" }}><span style={{ "width": "400px", "display": "inline-block" }}>Dispatch Notes</span>
										</td>
									</tr>
									<tr>
										<td class="semi" style={{ "borderLeft": "1px solid black", "borderRight": "1px solid black", "borderBottom": "1px solid black", "verticalAlign": "top", "padding": "2px 5px", "fontSize": "13px" }}><span style={{ "width": "180px", "display": "inline-block" }}>LINE HAUL RATE</span>
										</td>
										<td class="semi" style={{ "padding": "2px 5px", "fontSize": "14px", "borderBottom": "1px solid black", "verticalAlign": "top", "textAlign": "center" }}>  invoice_rate_haul
										</td>
										<td class="semi" style={{ "padding": "2px 5px", "fontSize": "14px", "borderRight": "1px solid black", "borderLeft": "1px solid black", "borderBottom": "1px solid black", "textAlign": "center" }} ><span style={{ "display": "inline-block" }}>shipper invoice instruction</span>
										</td>
									</tr>
									<tr>
										<td class="semi" style={{ "borderBottom": "1px solid black", "borderRight": "1px solid black", "borderLeft": "1px solid black" }}>Total Rate
										</td>
										<td class="semi" style={{ "borderRight": "1px solid black", "borderBottom": "1px solid black", "textAlign": "center" }}>${user.amount} ({user.payment_mode})
										</td>
										<td class="semi" style={{ "borderRight": "1px solid black", "borderBottom": "1px solid black" }}>

										</td>
									</tr>
								</tbody>
							</table>
							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0" }}>
								<tbody>
									<tr>
										<td style={{ "height": "20px" }}>

										</td>
									</tr>
								</tbody>
							</table>
							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>
								<tbody>
									<tr>
										<td class="pick" style={{ "background": "black", "color": "#ffffff", "textAlign": "center", "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px", "borderRight": "1px solid #ffffff" }}>PICK
										</td>
										<td class="stop" style={{ "background": "black", "color": "#ffffff", "textAlign": "center", "fontWeight": "700", "padding": "2px 5px", "fontSize": "14px" }}>STOP
										</td>
									</tr>
									<tr>
										<td class="semi" style={{ "borderRight": "1px solid black", "borderLeft": "1px solid black", "verticalAlign": "top", "borderBottom": "1px solid black" }}>

											<ol>
											{invoicePicksFromApi.length > 0 &&
												invoicePicksFromApi.map((item, index) => {
												return <li style={{ "marginBottom": "10px", "fontSize": "13px","whiteSpace":"pre-line" }} key = {index} >
													{item.value??''}
													<hr/></li>
											})}

											
												{/* <li style={{ "marginBottom": "10px", "fontSize": "13px" }}>
													pick name<br />pick_address
													city<br />
													state zip country</li><br />
													Pick Up : yyyy-mm-dd<br />
													Appointment : yyyy-mm-dd<br />
													Ref# : refrence number <br />
													Phone# : 1234567891<br />
													Appt Notes: pick-note goes here<br /> */}

											</ol>

										</td>

										<td class="semi" style={{ "borderBottom": "1px solid black", "borderRight": "1px solid black", "height": "20px", "verticalAlign": "top" }}>


											<ol>
											{invoiceDropsFromApi.length > 0 &&
												invoiceDropsFromApi.map((item, index) => {
												return <li style={{ "marginBottom": "10px", "fontSize": "13px","whiteSpace":"pre-line" }} key = {index}>
													{item.value??''}
													<hr/></li>
											})}


												{/* <li style={{ "marginBottom": "10px", "fontSize": "13px" }}>drop name<br />drop address
													city<br />
													state, zip, country</li><br />
												Must Deliver : yyyy-mm-dd<br />
												Appointment : yyyy-mm-dd<br />
												Ref# : refrence number<br />
												Phone# : 1234567891<br />
												Appt Notes: drop-note goes here<br />

												<br /> */}


											</ol>


										</td>
									</tr>
								</tbody>

							</table>

							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>
								<tbody>
									<tr>
										<td style={{ "height": "20px" }}>

											<p>	PAYMENT OPTIONS : </p>

											<p>	1) MAKE CHECKS PAYABLE TO : </p>

											<p>	NAME ON THE CHECK : VALUE LOGISTICS INC</p>

											<p>	CHECK MAILING ADDRESS : PO BOX 736045, DALLAS, TX,75373-6045 </p>                                                                                                   

											<p>	OR : YOU CAN SHARE THE SCANNED COPY OF CHECK AND IT WILL BE SUBMITTED ELECTRONICALLY.</p>

											<p>	2) ZELLE PAY - t@valueloads.com</p>


											<p>	3) ACH INSTRUCTIONS :</p>

											<p>	BANK : JPMORGAN CHASE BANK</p>

											<p>	ACCOUNT NUMBER : 669579978</p>

											<p>	ROUTING NUMBER : 061092387</p>

											<p>	WIRE TRANSFER ROUTING NUMBER : 021000021</p>

											<p>	SWIFT CODE : CHASUS33 (FOR CANADA CUSTOMERS)</p>
										</td>
									</tr>
								</tbody>
							</table>
{/* 
							<table cellspacing="0" cellpadding="0" border="0" class="center_table" style={{ "maxWidth": "800px", "margin": "0 auto", "backgroundColor": "#ffffff", "width": "100%", "padding": "0", "fontFamily": "'Open Sans', sans-serif" }}>
								<tbody>
									<tr>
										<td class="itelic" style={{ "fontFamily": "'Open Sans', sans-serif", "fontStyle": "italic", "fontSize": "13px" }}>Please pick and deliver as per time mentioned in the rate and load confirmation to avoid any deductions.<br />Invoice will be responsible for any damage to the commodity during the transit. Rate Agreed is all in.<br />Please send all the paperwork to <b>ap@valueloads.com</b> 'ap@valueloads.com'<br /> Quick pay available  at 4% deduction
											**Please send the signed BOL within 5 days of delivery.**
										</td>
									</tr>
								</tbody>
							</table> */}
						</View>
					</div>
				</Page>
			</Document>
		</>
	);
};


export default InvoiceInvoicePdfGenerator;