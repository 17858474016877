// export const host = "http://localhost:7005";
export const host = "https://api.valuedispatcher.com";


export const loginRoute = host+'/signin';
export const logOut = host+'/admin/sign-out';
export const userAuthentication = host+'/user/authentication';
export const loggedUser = host+'/user/logged';


export const getAllUserList = host+'/user/list';
export const getOneUserById = host+'/getoneuserbyid';
export const UploadUserImage = host+'/upload_user_image';
export const userLogout = host+'/admin/sign-out';  
export const formApprove = host+'/approve';

export const DeleteBookingDataById = host+'/register/delete';
export const GetUserRoles = host+'/role';
export const getpermitions = host+'/getpermissions';
export const addUser = host+'/useradd';
export const userupdate = host+'/userupdate';
export const getManagerData= host+'/managerData';
export const getTlData= host+'/teamleaderData';
export const getUserDataByToken = host+'/getuserinfo';


export const addCarriers = host+'/creedcarrier';
export const getAllCarrier = host+'/carrier/fetch';
export const GetCarriersDataById = host+'/carrier/getSingleAdminCarrier/';
export const UpdateCarrierData = host+'/carrier/carrier_update';
export const DeleteCarrierData = host+'/carrier/carrier_delete';
// export const InvoiceCarrierData = host+'/carrier/carrier_invoice';

export const addInvoices = host+'/creedinvoice';
export const getAllInvoice = host+'/invoice/fetch';
export const GetInvoicesDataById = host+'/invoice/getSingleAdminInvoice/';
export const UpdateInvoiceData = host+'/invoice/invoice_update';
export const DeleteInvoiceData = host+'/invoice/invoice_delete';
export const InvoiceCarrierData = host+'/invoice/carrier_invoice';
export const genrateInvoicePdf = host+'/invoice/genrateInvoicePdf';
export const UploadCarrierBodyImage= host+'/bodyimageupload';
export const deleteInvoiceImage = host+'/invoice/deleteInvoiceImage';
export const deleteInvoiceImage1 = host+'/invoice/deleteInvoiceImage1';

export const searchCarrier = host+'/carrier/search';
export const saveManagePicks = host+'/save-manage-picks';
export const saveManageDrops = host+'/save-manage-drops';

export const GetPicksByInvoiceId = host+'/GetPicksByInvoiceId/';
export const GetDropsByInvoiceId = host+'/GetDropsByInvoiceId/';


