import React, { useEffect, useState } from "react";
import axios from "axios";
import { DeleteCarrierData,GetCarriersDataById } from "proxyUrl";
import { useParams, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function CarrierDelete() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [carrierContentFromApi, setCarrierContentFromApi] = useState();
  const [loading, setLoading] = useState(false);
  // const [oldImage, setOldImage] = useState();
  const [user, setUser] = useState({
    companyName: "",
    nscNo: "",
    mcNo: "",
    dotNo:"",
    dispatcher: "",
    address: "",
    state: "",
    city: "",
    zipcode: "",
    phoneNo: "",
    email: "",
    payment_mode: "USD",
    amount: ""
  });
//   console.log(carrierContentFromApi);
  const GettingCarriercarrierContentFromApi = () => {
    axios
      .get(`${GetCarriersDataById}${id}`, {
        withCredentials: true,
      })
      .then((data) => {
        const carrier = data.data;
        setCarrierContentFromApi(carrier);
      });
  };

  const handleDelete = async () => {
    setLoading(true);

    try {
      await axios.delete(`${DeleteCarrierData}/${id}`).then(res =>{
       if (res.status === 204) {
        toast.success("Carrier Deleted successfully!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
       }
       else {
        toast.error("something happened wrong!", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
       }

       const delayfrfresh = () => {
        navigate("/admin/carriers");
      };
      // eslint-disable-next-line
      const delayRef = setTimeout(delayfrfresh, 3100);

      }).catch(err =>{
        if(err.response.status === 404) {
            toast.error("Carrier Not Found in Record!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
           }
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    GettingCarriercarrierContentFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUser({
      ...user,
      companyName: carrierContentFromApi ? carrierContentFromApi.companyName : "",

      email: carrierContentFromApi
        ? carrierContentFromApi.email
        : "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierContentFromApi]);

  // useEffect(() => {
  //   setOldImage(carrierContentFromApi ? carrierContentFromApi.image : "");
  // }, [carrierContentFromApi]);
  return (
    <div className="  my-10 flex h-[78vh] w-full flex-col items-center justify-center     gap-5 ">
      {/* <div className="input relative mx-2 mr-7 flex items-center justify-center rounded-lg border-2 border-blueSecondary   ">
        <div className=" h-[300px] w-[500px] rounded-lg   ">
          <img
            className="h-full w-full rounded-md object-cover "
            src={oldImage}
            alt=""
          />
        </div>
      </div> */}
      <h2 className=" mx-10 mb-10 text-center text-5xl font-semibold text-primaryBlue ">
        {user.companyName}
      </h2>
      <button
        className=" rounded-full text-xl hover:bg-red-500 duration-300 bg-red-400 px-10 py-5 text-white shadow-md "
        onClick={handleDelete}
        disabled={loading}
      >
       Delete
      </button>
      <ToastContainer/>
    </div>
  );
}

export default CarrierDelete;
