import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

const pageSize = 10; // Number of items per page

export default function CarrierTable(props) {
  const {  tableData, editRoute, deleteRoute } = props;

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(tableData.length / pageSize);

  // Calculate the index range of the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // Get the current page's data
  const currentData = tableData.slice(startIndex, endIndex);


  // Handle previous page
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  // Handle next page
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <>
      <section className="container mx-auto px-4 ">
        <div className=" flex flex-col">
          <div className="-mx-4  -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className=" overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50  dark:bg-gray-800">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 px-4 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        <div className="flex items-center gap-x-3">
                          <span>Id</span>
                        </div>
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        Company
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        NSC
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        MC
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        DOT
                      </th>
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        Dispatcher
                      </th>

         
                      <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        <span className="">Action</span>
                      </th>
                      
                      {/* <th
                        scope="col"
                        className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
                      >
                        <span className="">Invoice</span>
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900">
                    {tableData ? (
                      <>
                        {currentData.map((data, index) => (
                          <>
                            <tr>
                              <td className="whitespace-nowrap px-4 py-4 text-sm font-medium text-gray-700">
                                <div className="inline-flex items-center gap-x-3">
                                  <div className="flex items-center gap-x-2">
                                    <div>
                                      <h2 className="font-medium text-gray-800 dark:text-white ">
                                        {data.id}
                                      </h2>
                                    </div>
                                  </div>
                                </div>
                              </td>
                             
                              <td className=" px-4  py-4 text-sm text-gray-500 dark:text-gray-300">
                                {data.companyName}
                              </td>
                              <td className=" px-4  py-4 text-sm text-gray-500 dark:text-gray-300">
                                {data.nscNo}
                              </td>
                              <td className=" px-4  py-4 text-sm text-gray-500 dark:text-gray-300">
                                {data.mcNo}
                              </td>
                               <td className=" px-4  py-4 text-sm text-gray-500 dark:text-gray-300">
                                {data.dotNo}
                              </td>
                              <td className=" px-4  py-4 text-sm text-gray-500 dark:text-gray-300">
                                {data.dispatcher}
                              </td>
                             
                          
                              <td className="whitespace-nowrap px-4 py-4 text-sm">
                                <div className="flex items-center gap-x-6">
                                  <Link to={`${deleteRoute}/${data.id}`}>
                                    <button className="text-gray-500 transition-colors duration-200 hover:text-red-500 focus:outline-none dark:text-gray-300 dark:hover:text-red-500">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-5 w-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                        />
                                      </svg>
                                    </button>
                                  </Link>
                                  <Link to={`${editRoute}/${data.id}`}>
                                    <button className="text-gray-500 transition-colors duration-200 hover:text-yellow-500 focus:outline-none dark:text-gray-300 dark:hover:text-yellow-500">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="h-5 w-5"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                        />
                                      </svg>
                                    </button>
                                  </Link>
                                </div>
                              </td>

                              {/* <td className="whitespace-nowrap px-4 py-4 text-sm">
                                <div className=" items-center gap-x-6">
                                  <Link to={`${generateInvoice}/${data.id}`}>
                                    <button className="text-gray-500 transition-colors duration-200 hover:text-red-500 focus:outline-none dark:text-gray-300 dark:hover:text-red-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16"> <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/> </svg>
                                    </button>
                                  </Link>
                                </div>
                              </td> */}
                            </tr>
                          </>
                        ))}
                      </>
                    ) : (
                      <>Loading Data</>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 gap-4 flex items-center justify-end">
          <button
           onClick={handlePreviousPage} disabled={currentPage === 1}
            className="flex items-center gap-x-2 rounded-md border bg-white px-5 py-2 text-sm capitalize text-gray-700 transition-colors duration-200 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
              />
            </svg>
            <span>previous</span>
          </button>
         
          <button
           onClick={handleNextPage} disabled={currentPage === totalPages}
            className="flex items-center gap-x-2 rounded-md border bg-white px-5 py-2 text-sm capitalize text-gray-700 transition-colors duration-200 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-200 dark:hover:bg-gray-800"
          >
            <span>Next</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-5 w-5 rtl:-scale-x-100"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </button>
        </div>
      </section>
    </>
  );
}
