import React, { useState, useEffect } from "react";
import "./CarriersForm.css";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { UploadCarrierBodyImage, UploadFeatureImageUrl } from "proxyUrl";
import { UpdateCarrierData, GetCarriersDataById} from "proxyUrl";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const CarriersFormEdit = () => {
  const { id } = useParams();

  // const [letestImage, setLetestImage] = useState(null)
  const [carrierContentFromApi, setCarrierContentFromApi] = useState();
  // const [preview, setPreview] = useState(null);
  // const [content, setContent] = useState();
  // const [oldImage, setOldImage] = useState();
  // const [image, setImage] = useState();
  const [submiteed, setSubmiteed] = useState(false);
  const [status, setStatus] = useState();
  // const [setPaymentMode] = useState("USD");
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  // const handlePaymentModeChange = (event) => {
  //   setPaymentMode(event.target.value);
  // };
  // const handleImage = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setImage(selectedFile);
  //   setPreview(URL.createObjectURL(selectedFile));
  // };
  const navigate = useNavigate();
  const [user, setUser] = useState({
    companyName: "",
    nscNo: "",
    mcNo: "",
    dotNo:"",
    dispatcher: "",
    address: "",
    state: "",
    city: "",
    zipcode: "",
    phoneNo: "",
    email: "",
    payment_mode: "USD",
    amount: ""
  });

  const GettingCarriercarrierContentFromApi = () => {
    axios
      .get(`${GetCarriersDataById}${id}`, {
        withCredentials: true,
      })
      .then((data) => {
        const carrier = data.data;
        setCarrierContentFromApi(carrier);
      });
  };
  // if (image) {
    
  // }

  useEffect(() => {
    GettingCarriercarrierContentFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // companyName: "",
  //   nscNo: "",
  //   mcNo: "",
  //   dotNo:"",
  //   dispatcher: "",
  //   address: "",
  //   state: "",
  //   city: "",
  //   zipcode: "",
  //   phoneNo: "",
  //   email: "",
  //   payment_mode: "USD",
  
  //   amount: ""
  useEffect(() => {
    setUser({
      ...user,
      companyName: carrierContentFromApi ? carrierContentFromApi.companyName : "",
      nscNo: carrierContentFromApi ? carrierContentFromApi.nscNo : "",
      mcNo: carrierContentFromApi ? carrierContentFromApi.mcNo : "",
      dotNo: carrierContentFromApi ? carrierContentFromApi.dotNo : "",
      dispatcher: carrierContentFromApi ? carrierContentFromApi.dispatcher : "",
      address: carrierContentFromApi ? carrierContentFromApi.address : "",
      state: carrierContentFromApi ? carrierContentFromApi.state : "",
      city: carrierContentFromApi ? carrierContentFromApi.city : "",
      zipcode: carrierContentFromApi ? carrierContentFromApi.zipcode : "",
      phoneNo: carrierContentFromApi ? carrierContentFromApi.phoneNo : "",
      email: carrierContentFromApi ? carrierContentFromApi.email : "",
      payment_mode: carrierContentFromApi ? carrierContentFromApi.payment_mode : "USD",
      amount: carrierContentFromApi ? carrierContentFromApi.amount : "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrierContentFromApi]);

  // useEffect(() =>{
  // }, [letestImage])

  // useEffect(() => {
  //   setContent(carrierContentFromApi ? carrierContentFromApi.content : "");
  //   setStatus(carrierContentFromApi ? carrierContentFromApi.status : 1);
  //   setOldImage(carrierContentFromApi ? carrierContentFromApi.image : "");
  //   setLetestImage(carrierContentFromApi ? carrierContentFromApi.image : "");
  // }, [carrierContentFromApi]);

  let name, value;
  const handleInput = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };
  
  const sendData = async (e) => {
    e.preventDefault();
    setSubmiteed(true);
    // const imageArray = []
    //creating form data
  //  if (image) {
  //   const formData = new FormData();
  //   formData.append("image", image);
  //   await axios
  //     .post(UploadFeatureImageUrl, formData, {
  //       withCredentials: true,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         imageArray.push(res.data.imageUrl);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //  }

  console.log(user);
  await axios
  .put(
    `${UpdateCarrierData}/${id}`,
    {
      companyName: user.companyName,
      nscNo: user.nscNo,
      mcNo: user.mcNo,
      dotNo: user.dotNo,
      dispatcher: user.dispatcher,
      address: user.address,
      state: user.state,
      city: user.city,
      zipcode: user.zipcode,
      phoneNo: user.phoneNo,
      email: user.email,
      payment_mode: user.payment_mode,
      amount: user.amount,
      status: status,
    },
    {
      withCredentials: true,
    }
  )
  .then((res) => {
    console.log(res.status);
    if (res.status === 200) {
      console.log("Form submitted successfully");
      toast.success("Form submitted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delayfrfresh = () => {
        navigate("/admin/carriers");
      };
      // eslint-disable-next-line
      const delayRef = setTimeout(delayfrfresh, 3100);
    }
  })
  .catch((err) => {
    console.log(err.response.status);
    if (err.response.status === 400) {
      console.log("Please fill all data");
      toast.error("Please fill all data!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delay = () => {
        setSubmiteed(false);
      };
      // eslint-disable-next-line
      const myTimeout = setTimeout(delay, 3000);
    } else {
      console.log("something went wrong");
      toast.error("something went wrong!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delay = () => {
        setSubmiteed(false);
      };
      // eslint-disable-next-line
      const myTimeout = setTimeout(delay, 3000);
    }
  });
 
  };
  const tork = false;
  const [userPlace, setUserPlace] = useState({
    companyName: "Enter Carrier Company Name",
    nscNo: "Enter Carrier NSC No",
    mcNo: "Enter Carrier MC No",
    dotNo: "Enter Carrier DOT No",
    dispatcher: "Enter Dispatcher Name",
    address: "Enter Carrier Address",
    state: "Enter Carrier state",
    city: "Enter Carrier city",
    zipcode: "Enter Carrier zipcode",
    phoneNo: "Type Carrier Phone",
    email: "Enter Carrier Email",
    amount: "Enter Carrier Amount",
  });

  if (tork === true) {
    setUserPlace(null);
  }

  // useEffect(() => {}, [image]);
  return (
    <>
      <div className="mt-7  mb-2 rounded-lg bg-white shadow-xl dark:bg-navy-800 ">
        <div className="mx-auto w-[90%]  ">
          <form action="" method="POST" className="">
            <div className="">
              <div className="field w-[100%] ">
                <div className="input  relative">
                  <label htmlFor="companyName"> Company Name</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.companyName}`}
                    maxLength={80}
                    name="companyName"
                    value={user.companyName}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="nscNo" className="">
                    NSC Number
                  </label>
                  <input
                    type="text"
                    placeholder={`${userPlace.nscNo}`}
                    name="nscNo"
                    value={user.nscNo}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <div className="field">
                <div className="input relative">
                  <label htmlFor="mcNo"> MC Number</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.mcNo}`}
                    name="mcNo"
                    value={user.mcNo}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative ">
                  <label htmlFor="dotNo"> DOT Number</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.dotNo}`}
                    name="dotNo"
                    value={user.dotNo}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input relative ">
                  <label htmlFor="dispatcher"> Dispatcher Name</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.dispatcher}`}
                    name="dispatcher"
                    value={user.dispatcher}
                    onChange={handleInput}
                  />
                </div>
                
                <div className="input  relative ">
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.state}`}
                    name="state"
                    value={user.state}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field">
              <div className="input  relative">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.address}`}
                    maxLength={80}
                    name="address"
                    value={user.address}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.amount}`}
                    maxLength={80}
                    name="amount"
                    value={user.amount}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field">
              <div className="input  relative">
                  <label htmlFor="phoneNo">Phone Number</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.phoneNo}`}
                    maxLength={80}
                    name="phoneNo"
                    value={user.phoneNo}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.email}`}
                    maxLength={80}
                    name="email"
                    value={user.email}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field">
                <div className="input relative">
                  <label htmlFor="city"> City</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.city}`}
                    name="city"
                    maxLength={80}
                    value={user.city}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="zipcode">Zipcode</label>
                  <input
                    className="h-[53px] overflow-hidden !rounded-md   "
                    id="zipcode"
                    type="text"
                    value={user.zipcode}
                    onChange={handleInput}
                    name="zipcode"
                  />
                </div>
              </div>
              <div className="field">
                <div className="input relative">
                  <label htmlFor="status-select">Status:</label>
                  <select
                    id="status-select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="1">Active</option>
                    <option value="0">Disabled</option>
                  </select>
                </div>
                
                <div className="input relative">
                  <label htmlFor="payment_mode">Payment Mode</label>
                  {/* <input
                    type="text"
                    placeholder={`USD`}
                    name="payment_mode"
                    maxLength={80}
                    value={user.payment_mode}
                    onChange={handlePaymentModeChange}
                  /> */}
                   <input
                    className="h-[53px] overflow-hidden !rounded-md   "
                    id="payment_mode"
                    type="text"
                    value={user.payment_mode}
                    onChange={handleInput}
                    name="payment_mode"
                  />
                </div>
              </div>
             
              {submiteed ? (
                <div className="field cursor-pointer ">
                  <div className=" my-5 rounded-md bg-gray-300 py-3 px-12 text-white">
                    <button disabled>Loading....</button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="field cursor-pointer " onClick={sendData}>
                    <div className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
                      <button>Submit</button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default CarriersFormEdit;
