import React, { useState, useEffect } from "react";
import "./InvoicesForm.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Attachments from "./attachments";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UpdateInvoiceData, GetInvoicesDataById,host,deleteInvoiceImage,deleteInvoiceImage1,loggedUser} from "proxyUrl";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";



const InvoicesFormEdit = () => {
  const href = window.location.origin;
  const mangePicksAndDrops  = href+'/admin/invoices/managepicks';
  const { id } = useParams();
  const [invoiceContentFromApi, setInvoiceContentFromApi] = useState();
  const [loggeduser, setLoggeduser] = useState({permission:[]});
  const [content, setContent] = useState();
  const [files, setFiles] = useState([]);
  const [oldfiles, setOldfiles] = useState([]);
  const [submiteed, setSubmiteed] = useState(false);
  const [status, setStatus] = useState();
  const [showModal, setShowModal] = useState(false);

  const [picks, setPicks ] = useState([]);
  const [drops, setDrops ] = useState([]);

  const showHideModal = () => {
    setShowModal(true);
  }
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  
  const navigate = useNavigate();
  const [user, setUser] = useState({
    companyName: "",
    invoice_no: "",
    nscNo: "",
    mcNo: "",
    dotNo:"",
    dispatcher: "",
    address: "",
    state: "",
    city: "",
    zipcode: "",
    phoneNo: "",
    email: "",
    payment_mode: "USD",
    amount: "",
    equipment:"",
    size:"",
    pieces:"",
    commodity:"",
    miles:"",
    weight:"",
    min:"",
    max:"",
    invoiced:"",
  });

  const GettingInvoiceinvoiceContentFromApi = () => {
    axios
      .get(`${GetInvoicesDataById}${id}`, {
        withCredentials: true,
      })
      .then((data) => {
        const invoice = data.data;
        setInvoiceContentFromApi(invoice);
      });
    axios
      .get(loggedUser, {
        withCredentials: true,
      })
      .then((data) => {
        setLoggeduser(data?.data?.userInfo??{permission:[]});
      });
  };
  // if (image) {
    
  // }

  useEffect(() => {
    GettingInvoiceinvoiceContentFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUser({
      ...user,
      companyName: invoiceContentFromApi ? invoiceContentFromApi.companyName : "",
      invoice_no: invoiceContentFromApi ? invoiceContentFromApi.invoice_no : "",
      nscNo: invoiceContentFromApi ? invoiceContentFromApi.nscNo : "",
      mcNo: invoiceContentFromApi ? invoiceContentFromApi.mcNo : "",
      dotNo: invoiceContentFromApi ? invoiceContentFromApi.dotNo : "",
      dispatcher: invoiceContentFromApi ? invoiceContentFromApi.dispatcher : "",
      address: invoiceContentFromApi ? invoiceContentFromApi.address : "",
      state: invoiceContentFromApi ? invoiceContentFromApi.state : "",
      city: invoiceContentFromApi ? invoiceContentFromApi.city : "",
      zipcode: invoiceContentFromApi ? invoiceContentFromApi.zipcode : "",
      phoneNo: invoiceContentFromApi ? invoiceContentFromApi.phoneNo : "",
      email: invoiceContentFromApi ? invoiceContentFromApi.email : "",
      payment_mode: invoiceContentFromApi ? invoiceContentFromApi.payment_mode : "USD",
      amount: invoiceContentFromApi ? invoiceContentFromApi.amount : "",
      equipment: invoiceContentFromApi ? invoiceContentFromApi.equipment : "",
      size: invoiceContentFromApi ? invoiceContentFromApi.size : "",
      pieces: invoiceContentFromApi ? invoiceContentFromApi.pieces : "",
      commodity: invoiceContentFromApi ? invoiceContentFromApi.commodity : "",
      miles: invoiceContentFromApi ? invoiceContentFromApi.miles : "",
      weight: invoiceContentFromApi ? invoiceContentFromApi.weight : "",
      min: invoiceContentFromApi ? invoiceContentFromApi.min : "",
      max: invoiceContentFromApi ? invoiceContentFromApi.max : "",
      invoiced: invoiceContentFromApi ? invoiceContentFromApi.invoiced : "",
      
    });
    setPicks(invoiceContentFromApi?.picks??[]);
    setDrops(invoiceContentFromApi?.drops??[]);
    setOldfiles(invoiceContentFromApi?.attachments??[]);
    setStatus(invoiceContentFromApi ? invoiceContentFromApi.status : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceContentFromApi]);

 

  let name, value;
  const handleInput = (e) => {
    // console.log(e);
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };
  
  const sendData = async (e) => {
    e.preventDefault();
    setSubmiteed(true);


  console.log(user);
  await axios
  .put(
    `${UpdateInvoiceData}/${id}`,
    {
      companyName: user.companyName,
      invoice_no: user.invoice_no,
      nscNo: user.nscNo,
      mcNo: user.mcNo,
      dotNo: user.dotNo,
      dispatcher: user.dispatcher,
      address: user.address,
      state: user.state,
      city: user.city,
      zipcode: user.zipcode,
      phoneNo: user.phoneNo,
      email: user.email,
      payment_mode: user.payment_mode,
      amount: user.amount,
      status: status,
      picks:picks,
      drops:drops,
      equipment:user.equipment,
      size:user.size,
      pieces:user.pieces,
      commodity:user.commodity,
      miles:user.miles,
      weight:user.weight,
      min:user.min,
      max:user.max,
      files:files
      


    },
    {
      withCredentials: true,
    }
  )
  .then((res) => {
    console.log(res.status);
    if (res.status === 200) {
      console.log("Form submitted successfully");
      toast.success("Form submitted successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      const delayfrfresh = () => {
        navigate("/admin/invoices");
      };
      // eslint-disable-next-line
      const delayRef = setTimeout(delayfrfresh, 3100);
    }
  })
  .catch((err) => {
        if (err.response?.status === 403) {
          err.response?.data?.error.map((e)=>{
          toast.error(e, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        
          })
        }else{
          toast.error('Somthing went worng, Kindly refresh the page and try Again', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        const delay = () => {
          setSubmiteed(false);
        };
        // eslint-disable-next-line
        const myTimeout = setTimeout(delay, 3000);
  });
 
};
  const tork = false;
  const [userPlace, setUserPlace] = useState({
    companyName: "Enter Invoice Company Name",
    nscNo: "Enter Invoice NSC No",
    mcNo: "Enter Invoice MC No",
    dotNo: "Enter Invoice DOT No",
    dispatcher: "Enter Dispatcher Name",
    address: "Enter Invoice Address",
    state: "Enter Invoice state",
    city: "Enter Invoice city",
    zipcode: "Enter Invoice zipcode",
    phoneNo: "Type Invoice Phone",
    email: "Enter Invoice Email",
    amount: "Enter Invoice Amount",
  });

  if (tork === true) {
    setUserPlace(null);
  }
  const heandleChangeState = (e,i)=>{
    const newState = picks.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.id === i) {
        return {...obj, value: e.target.value};
      }

      // 👇️ otherwise return the object as is
      return obj;
    });

    setPicks(newState);
  }
  const heandleChangeStateDrop = (e,i)=>{
    const newState = drops.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.id === i) {
        return {...obj, value: e.target.value};
      }

      // 👇️ otherwise return the object as is
      return obj;
    });

    setDrops(newState);
  }

  const handleDeleteInvoiceImage = (image)=>{
     axios.post(deleteInvoiceImage,{
       image: image,
       id: id
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      let newdata = [];
      oldfiles.forEach(e=>{
        // alert(e.key);
        if(image != e.key || e.key == undefined){
          newdata.push(e);

        }
        setOldfiles(newdata);
      })
      
    })
    .catch((err) => {

    });
  }
  const handleDeleteInvoiceImage1 = (image)=>{
    if(user.invoiced == 'invoiced'){
      alert("This Invoice has already been genrated and sent to the customer you can't add or delete any document");
      return false;
    }
     axios.post(deleteInvoiceImage1,{
       image: image,
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      let newdata = [];
      files.forEach(e=>{
        if(image != e.key){
          newdata.push(e);

        }
        setFiles(newdata);
      })
      
    })
    .catch((err) => {

    });
  }

  return (
    <>
     <div className=" add-booking-header  relative  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 shadow-lg dark:bg-navy-800">
        <div className="text-xl font-bold text-white dark:text-white">
        </div>
        {/* <Link to={`admin/invoices/managepicks/${id}`}> */}
        <Link to={`${mangePicksAndDrops}/${id}`}>
          <button className="rounded-full bg-white p-1  px-3 text-navy-800 dark:bg-navy-900 dark:text-white">
            Add Picks/Drops
          </button>
        </Link>
      </div>
      <div className="mt-7  mb-2 rounded-lg bg-white shadow-xl dark:bg-navy-800 ">
        <div className="mx-auto w-[90%]  ">
          <form action="" method="POST" className="">
            <div className="">
              <div className="field w-[100%] ">
                <div className="input  relative">
                  <label htmlFor="companyName">Invoice No</label>
                  <input
                    type="text"
                    placeholder="Invoice No"
                    maxLength={80}
                    name="invoice_no"
                    value={user.invoice_no}
                    onChange={handleInput}  
                    required="required"
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="companyName"> Company Name</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.companyName}`}
                    maxLength={80}
                    name="companyName"
                    value={user.companyName}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="nscNo" className="">
                    NSC Number
                  </label>
                  <input
                    type="text"
                    placeholder={`${userPlace.nscNo}`}
                    name="nscNo"
                    value={user.nscNo}
                    onChange={handleInput}
                  />
                </div>

                <div className="input relative">
                  <label htmlFor="mcNo"> MC Number</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.mcNo}`}
                    name="mcNo"
                    value={user.mcNo}
                    onChange={handleInput}
                  />
                </div>

                
                <div className="input  relative ">
                  <label htmlFor="dotNo"> DOT Number</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.dotNo}`}
                    name="dotNo"
                    value={user.dotNo}
                    onChange={handleInput}
                  />
                </div>


              </div>

              <div className="field">
                
              </div>
              <div className="field">
                <div className="input relative ">
                  <label htmlFor="dispatcher"> Dispatcher Name</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.dispatcher}`}
                    name="dispatcher"
                    value={user.dispatcher}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.address}`}
                    maxLength={80}
                    name="address"
                    value={user.address}
                    onChange={handleInput}
                  />
                </div>
                
                
              </div>
              <div className="field">
                <div className="input relative">
                  <label htmlFor="city"> City</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.city}`}
                    name="city"
                    maxLength={80}
                    value={user.city}
                    onChange={handleInput}
                  />
                </div>

                <div className="input  relative ">
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.state}`}
                    name="state"
                    value={user.state}
                    onChange={handleInput}
                  />
                </div>
                

                <div className="input relative">
                  <label htmlFor="zipcode">Zipcode</label>
                  <input
                    className="h-[53px] overflow-hidden !rounded-md   "
                    id="zipcode"
                    type="text"
                    value={user.zipcode}
                    onChange={handleInput}
                    name="zipcode"
                  />
                </div>

                <div className="input  relative">
                  <label htmlFor="phoneNo">Phone Number</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.phoneNo}`}
                    maxLength={80}
                    name="phoneNo"
                    value={user.phoneNo}
                    onChange={handleInput}
                  />
                </div>

                
              </div>
              <div className="field">
             
                <div className="input  relative">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.email}`}
                    maxLength={80}
                    name="email"
                    value={user.email}
                    onChange={handleInput}
                  />
                </div>

                <div className="input  relative">
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="number"
                    placeholder={`${userPlace.amount}`}
                    maxLength={80}
                    name="amount"
                    value={user.amount}
                    onChange={handleInput}
                  />
                </div>
                
                <div className="input relative">
                  <label htmlFor="payment_mode">Payment Mode</label>
                   <select id="payment_mode"   name="payment_mode"  onChange={handleInput} >
                      <option value="USD" selected={user.payment_mode=='USD'?true:false}>USD</option>
                      <option value="CAD" selected={user.payment_mode=='CAD'?true:false} >CAD</option>
                  </select>
                </div>
                <div className="input relative">
                  <label htmlFor="status-select">Status:</label>
                  <select
                    id="status-select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="1" selected={user.status==1?true:false} >Active</option>
                    <option value="0" selected={user.status==0?true:false} >Disabled</option>
                  </select>
                </div>
              </div>
             
              {(user.invoiced != 'invoiced' || loggeduser.permission.includes(7) )?<Attachments fileNames={setFiles} files={files} oldfileNames={setOldfiles} setSubmiteed={setSubmiteed} />:''}
              {/* || loggeduser.permission.includes(7) */}
              {/* {loggeduser.permission}  */}
             {oldfiles.map((item,i)=>{
                    return(
                      <>
                      <div className="inline-grid grid-cols-2 gap-4" > 
                      <a href={item?.location} target="_blank" ><img src="/pdf.png" width="100px" /></a>
                      {(user.invoiced != 'invoiced'  || loggeduser.permission.includes(7) )?
                      <a href="javascript:void(0)" onClick={()=>{handleDeleteInvoiceImage(item?.key)}}><svg class="h-8 w-8 text-red-600"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg></a>
                     :''}
                      </div>
                      </>
                    )
                    })}
             {files.map((item,i)=>{
                    return(
                      <>
                      <div className="inline-grid grid-cols-2 gap-4" > 
                      <a href={item?.location} target="_blank" ><img src="/pdf.png" width="100px" /></a>
                      <a href="javascript:void(0)" onClick={()=>{handleDeleteInvoiceImage1(item?.key)}}><svg class="h-8 w-8 text-red-600"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg></a>
                      
                      </div>
                      </>
                    )
                    })}
              <hr />
              <br />
              <h2 style={{fontSize:'20px'}}>Load Info</h2>
              <div className="field">
             
                <div className="input  relative">
                  <label htmlFor="email">Equipment</label>
                  <input
                    type="text"
                    placeholder="Equipment"
                    maxLength={80}
                    name="equipment"
                    value={user.equipment}
                    onChange={handleInput}
                  />
                </div>
                
                <div className="input  relative">
                  <label htmlFor="email">Size</label>
                  <input
                    type="text"
                    placeholder="Equipment"
                    maxLength={80}
                    name="size"
                    value={user.size}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="email">Pieces</label>
                  <input
                    type="text"
                    placeholder="Pieces"
                    maxLength={80}
                    name="pieces"
                    value={user.pieces}
                    onChange={handleInput}
                  />
                </div>

                <div className="input  relative">
                  <label htmlFor="email">Commodity</label>
                  <input
                    type="text"
                    placeholder="Commodity"
                    maxLength={80}
                    name="commodity"
                    value={user.commodity}
                    onChange={handleInput}
                  />
                </div>

              </div>


              <div className="field">
             
               
                
                <div className="input  relative">
                  <label htmlFor="email">Miles</label>
                  <input
                    type="text"
                    placeholder="Miles"
                    maxLength={80}
                    name="miles"
                    value={user.miles}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="email">Weight</label>
                  <input
                    type="text"
                    placeholder="Weight"
                    maxLength={80}
                    name="weight"
                    value={user.weight}
                    onChange={handleInput}
                  />
                </div>

                <div className="input  relative">
                  <label htmlFor="email">Min </label>
                  <input
                    type="text"
                    placeholder="Min"
                    maxLength={80}
                    name="min"
                    value={user.min}
                    onChange={handleInput}
                  />
                </div>
                
                <div className="input  relative">
                  <label htmlFor="email">Max</label>
                  <input
                    type="text"
                    placeholder="Max"
                    maxLength={80}
                    name="max"
                    value={user.max}
                    onChange={handleInput}
                  />
                </div>
              </div>

              <hr />
              <br />
              <h2 style={{fontSize:'20px'}}>Picks/ Drops</h2>
              {/* *********************************************************************** */}
              
              <div className="field">
                <div className="input relative">
                  <label htmlFor="status-select">Picks</label>
                    {picks.map((item,i)=>{
                      return(
                        <>
                        <textarea key={i}  value={item.value} rows="4" onChange={(e)=> { heandleChangeState(e,item.id)}} className="input_spacer"/>
                        </>
                      )
                      })}
                      <div className="picks-btn-group" role="group">
                        <button type="button"  className="add_more_btn" onClick={(e)=>{ setPicks(oldArray=>[...oldArray, {id: picks.length,  value: 'Name: \nAddress: \nCity: \nProvince:'},]);   }}>+</button>
                        <button type="button" className="remove_btn" onClick={(e)=>{ setPicks((previousArr) => (previousArr.slice(0, -1)));   }}>-</button>
                      </div>
                      
                
                </div>
                
                
                <div className="input relative">
                  <label htmlFor="payment_mode">Drops</label>
                  {drops.map((item,i)=>{
                    return(
                      <>
                      <textarea key={i}  value={item.value} rows="4" onChange={(e)=> { heandleChangeStateDrop(e,item.id)}} children className="input_spacer"/>
                      </>
                    )
                    })}
                  <div className="picks-btn-group">
                    <input type="button" className="add_more_btn" onClick={(e)=>{  setDrops(oldArray=>[...oldArray, {id: drops.length,  value: 'Name: \nAddress: \nCity: \nProvince:'},]);  }} value="+"/>
                    <input type="button" className="remove_btn" onClick={(e)=>{  setDrops((previousArr) => (previousArr.slice(0, -1)));  }} value="-"/>
                  </div>
                </div>

              </div>
               
{/* ******************************************************************************* */}



              <div className="relative mx-2">
              

                {/* <h2 className="mb-1"> Pick/Drop</h2>
                <div className="hidden">
                  <CKEditor
                    value={content}
                    editor={ClassicEditor}
                    placeholder={""}
                    data=""
                    onReady={(editor) => {}}
                    config={{
                      // plugins: [ Essentials ],
                      ckfinder: {
                        // The URL that the images are uploaded to.
                        // uploadUrl: "/upload",

                        // Enable the XMLHttpRequest.withCredentials property.
                        withCredentials: true,

                        // Headers sent along with the XMLHttpRequest to the upload server.
                        headers: {
                          "X-CSRF-TOKEN": "CSFR-Token",
                          Authorization: "Bearer <JSON Web Token>",
                        },
                        // uploadUrl: "/api/upload-image",
                      },
                    }}
                    // onInit={editor => {
                    //   editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                    //     return new MyUploadAdapter(loader);
                    //   };
                    // }}
                    onChange={(handleInput, editor) => {
                      const data = editor.getData();
                      setContent(data);
                    }}
                  />
                </div>
                <CKEditor
                  value={content}
                  editor={ClassicEditor}
                  onInit={(editor) => {
                    //// Here the editor is ready to be used
                  }}
                  onChange={(handleInput, editor) => {
                    // console.log(handleInput,8757847878787878787);
                    const data = editor.getData();
                    setContent(data);
                  }}
                  config={{
                    // plugins: [ Essentials ],
                    ckfinder: {
                      // The URL that the images are uploaded to.
                      // uploadUrl: `${UploadInvoiceBodyImage}`,

                      // Enable the XMLHttpRequest.withCredentials property.
                      withCredentials: true,

                      // Headers sent along with the XMLHttpRequest to the upload server.
                    },
                  }}
                /> */}
              </div>
              {submiteed ? (
                <div className="field cursor-pointer ">
                  <div className=" my-5 rounded-md bg-gray-300 py-3 px-12 text-white">
                    <button disabled>Loading....</button>
                  </div>
                </div>
              ) : (
                <>
                {(user.invoiced != 'invoiced'  || loggeduser.permission.includes(7) )?
                  <div className="field cursor-pointer " onClick={sendData}>
                    <div className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
                      <button>Submit</button>
                    </div>
                  </div>: ''}
                </>
              )}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default InvoicesFormEdit;
