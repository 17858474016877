import React, { useState, useEffect } from "react";
import "./InvoicesForm.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UpdateInvoiceData, GetInvoicesDataById} from "proxyUrl";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {saveManagePicks, saveManageDrops} from "proxyUrl";

const ManagePicksDrops = () => {
  const { id } = useParams();
  const [submiteed, setSubmiteed] = useState(false);
  const [dropSubmited, setDropSubmited] = useState(false);

  const [status, setStatus] = useState();
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const navigate = useNavigate();

  const [addPicks, setAddpicks] = useState({
    shipment_id:"",
    invoice_id:id,
    p_name: "",
    p_address: "",
    p_city: "",
    p_state:"",
    p_zip: "",
    p_country: "",
    p_contact: "",
    p_ref: "",
    p_phone: "",
    p_email: "",
    p_ready: "",
    p_rtime: "",
    p_appt:"",
    p_atime:"",
    p_appt_note:""
  });
  const [addDrops, setAddDrops] = useState({
    shipment_id:"",
    invoice_id:id,
    d_name: "",
    d_address: "",
    d_city: "",
    d_state:"",
    d_zip: "",
    d_country: "",
    d_contact: "",
    d_ref: "",
    d_phone: "",
    d_email: "",
    d_ready: "",
    d_rtime: "",
    d_appt:"",
    d_atime:"",
    d_appt_note:""
  });

  let name, value;
  const handleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setAddpicks({ ...addPicks, [name]: value });
  };
  let d_name, d_value;
  const handleDropsInput = (e) => {
    d_name = e.target.name;
    d_value = e.target.value;
    setAddDrops({ ...addDrops, [d_name]: d_value });
  };
  const sendAddPicksData = async (e) => {
    e.preventDefault();
   // console.log("form data is here on submit",addPicks)
    await axios.post(
      saveManagePicks,
      {
        shipment_id:addPicks.shipment_id,
        invoice_id:id,
        p_name: addPicks.p_name,
        p_address: addPicks.p_address,
        p_city: addPicks.p_city,
        p_state:addPicks.p_state,
        p_zip: addPicks.p_zip,
        p_country: addPicks.p_country,
        p_contact:addPicks.p_contact,
        p_ref: addPicks.p_ref,
        p_phone: addPicks.p_phone,
        p_email: addPicks.p_email,
        p_ready: addPicks.p_ready,
        p_rtime: addPicks.p_rtime,
        p_appt: addPicks.p_appt,
        p_atime: addPicks.p_atime,
        p_appt_note: addPicks.p_appt_note
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log("Form submitted successfully");
        toast.success("Form submitted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const delayfrfresh = () => {
          navigate("/admin/invoices");
        };
        const delayRef = setTimeout(delayfrfresh, 3100);
      }
    })
    .catch((err) => {
      console.log(err.response.status);
      if (err.response.status === 400) {
        console.log("Please fill all data2");
        toast.error("Please fill all data3!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const delay = () => {
          setSubmiteed(false);
        };
        const myTimeout = setTimeout(delay, 3000);
      } else {
        console.log("something went wrong1");
        toast.error("something went wrong2!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        
      }
    });
  };

  const sendAddDropsData = async (e) => {
    e.preventDefault();
    console.log("add drops data is here on submit ",addDrops)
    await axios.post(
      saveManageDrops,
      {
        shipment_id:addDrops.shipment_id,
        invoice_id:id,
        d_name: addDrops.d_name,
        d_address: addDrops.d_address,
        d_city: addDrops.d_city,
        d_state:addDrops.d_state,
        d_zip: addDrops.d_zip,
        d_country: addDrops.d_country,
        d_contact:addDrops.d_contact,
        d_ref: addDrops.d_ref,
        d_phone: addDrops.d_phone,
        d_email: addDrops.d_email,
        d_ready: addDrops.d_ready,
        d_rtime: addDrops.d_rtime,
        d_appt: addDrops.d_appt,
        d_atime: addDrops.d_atime,
        d_appt_note: addDrops.d_appt_note
      },
      {
        withCredentials: true,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        console.log("Form submitted successfully");
        toast.success("Form submitted successfully!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const delayfrfresh = () => {
          navigate("/admin/invoices");
        };
        const delayRef = setTimeout(delayfrfresh, 3100);
      }
    })
    .catch((err) => {
      console.log(err.response.status);
      if (err.response.status === 400) {
        console.log("Please fill all data2");
        toast.error("Please fill all data3!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const delay = () => {
          setDropSubmited(false);
        };
        const myTimeout = setTimeout(delay, 3000);
      } else {
        console.log("something went wrong1");
        toast.error("something went wrong2!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
  };


  return (
    <>
      <div className="mt-7  mb-2 rounded-lg bg-white shadow-xl dark:bg-navy-800 ">
      <div className=" add-booking-header  relative  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 shadow-lg dark:bg-navy-800">
        <div className="text-xl font-bold text-white dark:text-white">
          Add Pick for Invoice#{id}
        </div>
      </div>
        <div className="mx-auto w-[90%]  ">
          <form action="" method="POST" className="">
            <div className="">
              <div className="field w-[100%] ">
              <div className="input  relative">
                  <label htmlFor="shipment_id">Pro No</label>
                  <input
                    type="text"
                    placeholder=""
                    maxLength={80}
                    name="shipment_id"
                    value={addPicks.shipment_id}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_name">Name</label>
                  <input
                    type="text"
                    placeholder="name"
                    maxLength={80}
                    name="p_name"
                    value={addPicks.p_name}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_address" className="">
                    Address
                  </label>
                  <input
                    type="text"
                    placeholder="address"
                    name="p_address"
                    value={addPicks.p_address}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_city" className="">
                    City
                  </label>
                  <input
                    type="text"
                    placeholder="city"
                    name="p_city"
                    value={addPicks.p_city}
                    onChange={handleInput}
                  />
                </div>
                
              </div>
              <div className="field w-[100%] ">
              <div className="input relative">
                  <label htmlFor="p_state" className="">
                    State
                  </label>
                  <input
                    type="text"
                    placeholder="state"
                    name="p_state"
                    value={addPicks.p_state}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_zip">Zip</label>
                  <input
                    type="text"
                    placeholder=""
                    maxLength={80}
                    name="p_zip"
                    value={addPicks.p_zip}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_country" className="">
                    Country
                  </label>
                  <input
                    type="text"
                    placeholder="country"
                    name="p_country"
                    value={addPicks.p_country}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_contact" className="">
                    Contact
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="p_contact"
                    value={addPicks.p_contact}
                    onChange={handleInput}
                  />
                </div>
               
              </div>
              <div className="field w-[100%] ">
                
                <div className="input  relative">
                  <label htmlFor="p_phone">Phone</label>
                  <input
                    type="text"
                    placeholder=" phone"
                    maxLength={80}
                    name="p_phone"
                    value={addPicks.p_phone}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_email" className="">
                    Email
                  </label>
                  <input
                    type="text"
                    placeholder="email "
                    name="p_email"
                    value={addPicks.p_email}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_ready" className="">
                    Ready
                  </label>
                  <input
                    type="text"
                    placeholder="pick ready date"
                    name="p_ready"
                    value={addPicks.p_ready}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_rtime" className="">
                    Time
                  </label>
                  <input
                    type="text"
                    placeholder=" time"
                    name="p_rtime"
                    value={addPicks.p_rtime}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field w-[100%] ">
                <div className="input  relative">
                  <label htmlFor="p_appt"> Appt</label>
                  <input
                    type="text"
                    placeholder=""
                    maxLength={80}
                    name="p_appt"
                    value={addPicks.p_appt}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_atime" className="">
                    Appt time
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="p_atime"
                    value={addPicks.p_atime}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_appt_note" className="">
                    Appt Note
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="p_appt_note"
                    value={addPicks.p_appt_note}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="p_ref" className = "">
                    Ref#
                  </label>
                  <input
                    type="text"
                    placeholder="ref "
                    name="p_ref"
                    value={addPicks.p_ref}
                    onChange={handleInput}
                  />
                </div>
                
              </div>
              {submiteed ? (
                <div className="field cursor-pointer ">
                  <div className=" my-5 rounded-md bg-gray-300 py-3 px-12 text-white">
                    <button disabled>Loading....</button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="field cursor-pointer " onClick={sendAddPicksData}>
                    <div className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
                      <button>Add Picks</button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>

        <div className="mt-7  mb-2 rounded-lg bg-white shadow-xl dark:bg-navy-800 ">
          <div className=" add-booking-header  relative  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 shadow-lg dark:bg-navy-800">
            <div className="text-xl font-bold text-white dark:text-white">
              Add Drops for Invoice#{id}
            </div>
          </div>
          <div className="mx-auto w-[90%]  ">
            <form action="" method="POST" className="">
              <div className="">
                <div className="field w-[100%] ">
                <div className="input  relative">
                    <label htmlFor="shipment_id">Pro No</label>
                    <input
                      type="text"
                      placeholder=""
                      maxLength={80}
                      name="shipment_id"
                      value={addDrops.shipment_id}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_name">Name</label>
                    <input
                      type="text"
                      placeholder="name"
                      maxLength={80}
                      name="d_name"
                      value={addDrops.d_name}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_address" className="">
                      Address
                    </label>
                    <input
                      type="text"
                      placeholder="address"
                      name="d_address"
                      value={addDrops.d_address}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_city" className="">
                      City
                    </label>
                    <input
                      type="text"
                      placeholder="city"
                      name="d_city"
                      value={addDrops.d_city}
                      onChange={handleDropsInput}
                    />
                  </div>
                  
                </div>
                <div className="field w-[100%] ">
                <div className="input relative">
                    <label htmlFor="d_state" className="">
                      State
                    </label>
                    <input
                      type="text"
                      placeholder="state"
                      name="d_state"
                      value={addDrops.d_state}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_zip">Zip</label>
                    <input
                      type="text"
                      placeholder=""
                      maxLength={80}
                      name="d_zip"
                      value={addDrops.d_zip}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_country" className="">
                      Country
                    </label>
                    <input
                      type="text"
                      placeholder="country"
                      name="d_country"
                      value={addDrops.d_country}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_contact" className="">
                      Contact
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      name="d_contact"
                      value={addDrops.d_contact}
                      onChange={handleDropsInput}
                    />
                  </div>
                
                </div>
                <div className="field w-[100%] ">
                  
                  <div className="input  relative">
                    <label htmlFor="d_phone">Phone</label>
                    <input
                      type="text"
                      placeholder=" phone"
                      maxLength={80}
                      name="d_phone"
                      value={addDrops.d_phone}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_email" className="">
                      Email
                    </label>
                    <input
                      type="text"
                      placeholder="email "
                      name="d_email"
                      value={addDrops.d_email}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_ready" className="">
                      Ready
                    </label>
                    <input
                      type="text"
                      placeholder="pick ready date"
                      name="d_ready"
                      value={addDrops.d_ready}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_rtime" className="">
                      Time
                    </label>
                    <input
                      type="text"
                      placeholder=" time"
                      name="d_rtime"
                      value={addDrops.d_rtime}
                      onChange={handleDropsInput}
                    />
                  </div>
                </div>
                <div className="field w-[100%] ">
                  <div className="input  relative">
                    <label htmlFor="d_appt"> Appt</label>
                    <input
                      type="text"
                      placeholder=""
                      maxLength={80}
                      name="d_appt"
                      value={addDrops.d_appt}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_atime" className="">
                      Appt time
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      name="d_atime"
                      value={addDrops.d_atime}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_appt_note" className="">
                      Appt Note
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      name="d_appt_note"
                      value={addDrops.d_appt_note}
                      onChange={handleDropsInput}
                    />
                  </div>
                  <div className="input relative">
                    <label htmlFor="d_ref" className = "">
                      Ref#
                    </label>
                    <input
                      type="text"
                      placeholder="ref "
                      name="d_ref"
                      value={addDrops.d_ref}
                      onChange={handleDropsInput}
                    />
                  </div>
                  
                </div>
                {dropSubmited ? (
                  <div className="field cursor-pointer ">
                    <div className=" my-5 rounded-md bg-gray-300 py-3 px-12 text-white">
                      <button disabled>Loading....</button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="field cursor-pointer " onClick={sendAddDropsData}>
                      <div className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
                        <button>Add Drops</button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </form>
          </div>

        </div>
        
       </div>

      

    
      <ToastContainer />
    </>
  );
};

export default ManagePicksDrops;
