import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserDataTable } from "../default/variables/columnsData";
import ComplexTable from "views/admin/default/components/ComplexTable";
import { getAllUserList } from "proxyUrl";
import axios from "axios";
import { useSelector } from 'react-redux';
export default function User() {

  const [user, setUser] = useState([]);
  const CurrentUserDetails = useSelector(state => state.user);
  const fetchData = () => {
    axios
      .post(
        getAllUserList,
        {
          id: CurrentUserDetails.user.userInfo.user_id
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        return res;
      })
      .then((userdata) => {
        const userData = userdata.data;
        setUser(userData);
      });
  };
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className=" add-booking-header  relative overflow-hidden  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 py-3 shadow-lg dark:bg-navy-800">
        {/* <div className="w-1 h-full bg-primaryBlue absolute top-0 left-0 "></div> */}
        {/* <div className="w-1 h-full bg-primaryBlue absolute top-0 right-0 "></div> */}
        <div className="text-xl font-bold text-white dark:text-white">
          All Users
        </div>
        <Link to={`/admin/users/add`}>
          <button className="rounded-full bg-white p-1  px-3 text-navy-900 dark:bg-navy-900 dark:text-white">
            Add Users
          </button>
        </Link>
      </div>
      <div className="relative mt-0  grid w-full grid-cols-1 gap-5 md:grid-cols-1">
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700 ">
          <thead className="bg-gray-50  dark:bg-gray-800">
            <tr>

              <th
                scope="col"
                className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
              >
                Phone
              </th>
              <th
                scope="col"
                className="px-4 py-3.5 text-left text-md font-sm text-gray-700 rtl:text-right dark:text-gray-400"
              >
                <span className="">Action</span>
              </th>

            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-gray-900">
            {user ? (
              <>
                {user.map((data, index) => (
                  <>
                    <tr>


                      <td className=" px-4  py-4 text-sm text-gray-500 dark:text-gray-300">
                        {data.name}
                      </td>
                      <td className=" px-4  py-4 text-sm text-gray-500 dark:text-gray-300">
                        {data.email}
                      </td>
                      <td className=" px-4  py-4 text-sm text-gray-500 dark:text-gray-300">
                        {data.phone}
                      </td>

                      <td className="whitespace-nowrap px-4 py-4 text-sm">
                        <div className=" items-center gap-x-6">
                          <Link to={`/admin/users/edit/${data.id}`}>
                            <button className="text-gray-500 transition-colors duration-200 hover:text-yellow-500 focus:outline-none dark:text-gray-300 dark:hover:text-yellow-500">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-5 w-5"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                              </svg>
                            </button>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </>
                ))}
              </>
            ) : (
              <>Loading Data</>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}
