
import React, { useState, useEffect } from "react";
// import React, { useState, useEffect } from "react";

import { host} from "proxyUrl";

const API_METHOD = 'POST'
const STATUS_IDLE = 0
const STATUS_UPLOADING = 1

const Attachments = ({fileNames,oldfileNames,myfiles,setSubmiteed}) => {
    const [files, setFiles] = React.useState([])
    const [status, setStatus] = React.useState(STATUS_IDLE)
    const API_URL = host+'/files';

    useEffect(()=>{
        if(files.length>0){
            handleUploadClick();
        }
    },[files])
    const uploadFiles = (data)=> {
        
        setStatus(STATUS_UPLOADING);

        fetch(API_URL, {
            method: API_METHOD,
            body: data,

        })
        .then((res) => res.json())
        .then((data) =>{
            data.forEach((d)=>{
                fileNames(oldArray => [...oldArray,d]);
            });
            setSubmiteed(false);

        })
        .catch((err) => console.error(err))
        .finally(() => setStatus(STATUS_IDLE))
    }

    const packFiles = (files)=> {
        const data = new FormData();

        [...files].forEach((file, i) => {
            data.append(`files`, file)
        })
        return data
    }

    const handleUploadClick = () => {
        setSubmiteed(true);
        if (files.length) {
            const data = packFiles(files)
            uploadFiles(data)
        }
        else{
            alert('Kindly select the file first');
            return false;
        }
    }

    const renderFileList = () => (<ol>
        {/* {[...files].map((f, i) => (
            <li key={i}>{f.name} - {f.type}</li>
        ))} */}
    </ol>)

    const renderButtonStatus = () => (
        (status === STATUS_IDLE) ? 
            'Send to server' : 
           'uploading......'
    )

    return (<div class="mb-3" >
        
         <label for="formFileMultiple" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white" >Attachments</label>
        <input class="block w-full text-lg text-gray-900 border float-left w-5/6 border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
            type="file"
            accept="application/pdf" 
            multiple
            onChange={(e)=>{ setFiles(e.target.files); }} />
        {/* {renderFileList()} */}
        {/* <a href="javascript:void(0)" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded float-right "
         onClick={handleUploadClick} disabled={status === STATUS_UPLOADING}>
            {renderButtonStatus()}
        </a> */}
    </div>)
}



export default Attachments;