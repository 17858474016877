import React, { useEffect, useState } from "react";
import "./CreateUser.css";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {
  getpermitions,
  addUser
} from "proxyUrl";
import axios from "axios";

const UserEdit = () => {
  const navigate = useNavigate();

  const [permission, setPermission] = useState([]);
  const [selected, setSelected] = useState([]);
  const [submiteed, setSubmiteed] = useState(false);
  const [user, setUser] = useState({is_active:'1'});
  const [errors, setErrors] = useState({});

  const handleInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log(selected);
  },[selected]);
  useEffect(() => {
    
   
        axios.get(getpermitions, {
          withCredentials: true,
        })
        .then((res) => {
          return res;
        })
        .then((data) => {
          let permissionOption = [];

          data.data.forEach(element => {
            const id = element.id;
            const permissionName = element.name;
            const is_dissabled = element.status === "inactive" ? true : false;
            const option = {
              value: id,
              label: permissionName,
              disabled: is_dissabled,
            };
            permissionOption.push(option);
            
          }); 
          setPermission(permissionOption);
          
        }).catch((err)=>{

        });

      
  },[]);


  const addUserFunction = async (e) => {
    setErrors({})
    e.preventDefault();
    setSubmiteed(true);
    axios.post(addUser,{
      selected:selected,
      user:user
    }, {
     
      withCredentials: true,
      })
      .then((res) => {
        setSubmiteed(false);
        toast.success(res.data?.massage, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          navigate("/admin/users");
        }, 2000);
      }).catch((err)=>{
        // console.log(err.response.data?.errors??{});
        setErrors(err.response.data?.errors??{});
        setSubmiteed(false);
      })
  
  }


  
  // console.log(selected);
  // ROLES .......... STARTING..........}
  return (
    <>
      <div className=" mt-7 rounded-2xl py-4 dark:bg-navy-800   ">
        <form
          action=""
          method="POST"
          className="create-user-form bg-white   dark:bg-navy-800"
        >
          <div className="create-form-wrapper">
            <div className="field">
              <div className="input">
                <label
                  htmlFor="userName"
                  className="text-navy-900 dark:text-white"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  placeholder="User name"
                  id="userName"
                  name="name"
                  value={user?.name}
                  onChange={handleInput}
                />
                <span className="text-red-400"> {errors['user.name']??''}</span>
              </div>
              <div className="input">
                <label
                  htmlFor="phone"
                  className="text-navy-900 dark:text-white"
                >
                  Phone
                </label>
                <input
                  type="number"
                  placeholder="User phone number"
                  name="phone"
                  id="phone"
                  value={user?.phone}
                  onChange={handleInput}
                />
                
                <span className="text-red-400"> {errors['user.phone']??''}</span>
              </div>
            </div>
            <div className="field mt-[-12px]">
              <div className="input relative ">
                <label
                  htmlFor="email"
                  className="text-navy-900 dark:text-white"
                >
                  Email
                </label>
                <input
                  type="text"
                  placeholder="User email"
                  name="email"
                  id="email"
                  value={user?.email}
                  onChange={handleInput}
                />
                
                <span className="text-red-400"> {errors['user.email']??''}</span>
              </div>
              <div className="input">
                {" "}
                <label
                  htmlFor="password"
                  className="text-navy-900 dark:text-white"
                >
                  password
                </label>
                <input
                  type="text"
                  placeholder="User password"
                  name="new_password"
                  id="password"
                  value={user?.new_password}
                  onChange={handleInput}
                />
                <span className="text-red-400"> {errors['user.new_password']??''}</span>
              </div>
            </div>
            <div className=" flex w-full  gap-2">
              
              <div className="mx-2 w-[50%]">
                <label
                  htmlFor="country"
                  className="text-navy-900 dark:text-white"
                >
                  permissions
                </label>
                <MultiSelect
                  options={permission}
                  value={selected}
                  onChange={setSelected}
                  data={selected}
                  labelledBy="Select"
                  className=" mt-2"
                />
              </div>

              <div className="mx-2 w-[50%]">
                <div className="input relative">
                  <label htmlFor="status-select">Status</label>
                  <select
                    name="is_active"
                    id="status-select"
                    value={user?.is_active}
                    onChange={handleInput}
                  >
                    <option value="0">Disabled</option>
                    <option value="1" >Active</option>
                  </select>
                </div>
                <div className="input relative"></div>
              </div>

            </div>
            
            

            <div className="bg-transperent mx-2 mt-5  flex items-center justify-center">
              {submiteed ? (
                <div className="field cursor-pointer ">
                  <div className=" my-5 rounded-md bg-gray-300 py-3 px-12 text-white">
                    <button disabled>Loading....</button>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="field cursor-pointer "
                    onClick={addUserFunction}
                  >
                    <div className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
                      <button type="submit"> Submit </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

export default UserEdit;
