import {React,useState} from "react";
import "./InvoicesForm.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
 
import CarrierInvoicePdfGenerator from "./CarrierInvoicePdfGenerator";
import { useParams,useNavigate } from "react-router-dom";

import axios from "axios";
import { genrateInvoicePdf} from "proxyUrl";

const InvoiceInvoice = () => {
  
	const { id } = useParams();
  const [submiteed, setSubmiteed] = useState(false);
  const [invoiced, setinvoiced] = useState('pending');
  const navigate = useNavigate();
  const SendMail = () => {
    setSubmiteed(true);

   axios.get(
    (genrateInvoicePdf+'/'+id),{
      withCredentials:true
    }
    )
    .then((res) => {
      toast.success(res.data?.msg, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout( () => {
        navigate("/admin/invoices");
      }, 3100);
    })
    .catch((err) => {
      toast.error(err.response?.data, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSubmiteed(false);

    });
  }
 return (
  <>
  <div className="mt-7  mb-2 rounded-lg bg-white shadow-xl dark:bg-navy-800 ">
    <div className="mx-auto w-[90%]  ">
      <div id="InvoicePdf">
          <CarrierInvoicePdfGenerator setinvoiced={setinvoiced} />
      </div>
      <div>
        <div class="field cursor-pointer ">
          <div class=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white" style={{background:'#b22121'}}>
            {/* <a href={genrateInvoicePdf+'/'+id} target="_blank" rel="noopener noreferrer">Send Mail</a> */}
            <button onClick={() => window.print()} >Download PDF</button> 
          </div>
          <div class=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
            {/* <a href={genrateInvoicePdf+'/'+id} target="_blank" rel="noopener noreferrer">Send Mail</a> */}
            {submiteed ? (<button>Loading.....</button>):(<button onClick={SendMail}>{invoiced=='pending'?'Send Invoice':'Resend Invoice'}</button>) }
          </div>
        </div>
      </div>
    </div>
  </div>
  <ToastContainer />

</>
 )
};



export default InvoiceInvoice;