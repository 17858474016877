import React, { useEffect, useState } from "react";
import "./InvoicesForm.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast, ToastContainer } from "react-toastify";

import Attachments from "./attachments";
import "react-toastify/dist/ReactToastify.css";
import { addInvoices,deleteInvoiceImage1} from "proxyUrl";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const InvoicesForm = () => {
// return(<>dsfsd</>);
  const [content, setContent] = useState();
  const [submiteed, setSubmiteed,show, setShow] = useState(false);
  const [cdata, setCdata] = useState(false);
  const [items, setItems ] = useState([]);
  const [files, setFiles ] = useState([]);
  const [carriersListData, setCarriersListData ] = useState([]);
  const [picks, setPicks ] = useState([
    {id: 0,  value: 'Name: \nAddress: \nCity: \nProvince:'},
  ]);
  const [drops, setDrops ] = useState([
    {id: 0, value: 'Name: \nAddress: \nCity: \nProvince:'},
  ]);
  const [status, setStatus] = useState("1");
 
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 

  const navigate = useNavigate();
  const [user, setUser] = useState({payment_mode:'USD',invoice_no:''});

  let name, value;
  const handleInput = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

    /******Search carrier name********/
    const [companyName, setcompanyName] = useState("");

    const handleChange = (e) => {
      setcompanyName(e.target.value);
    };
    const handleKeyUp = async(e) => {
      // if ((e.target.value).length>0) {
      //   setcompanyName(e.target.value);
       
      //   await axios.post(
      //     searchCarrier,
      //     {
      //       companyName: e.target.value,
      //     },
      //     {
      //       withCredentials: true,
      //     }
      //   )
      //   .then((res) => {

      //     if (res.status === 200) {
      //       setCdata(!cdata);
      //       setItems([res.data.result]);
      //       if(items.length>0){
      //           items.map((item, index) => setCarriersListData(item));
      //       }
      //       console.log('carrierResult api======', carriersListData);

      //     }else{
      //      setCdata(!cdata);
      //      setCarriersListData([]);
      //      console.log('carrierResult else======',items)

      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // }else{
      //   setItems([]);
      //   setCarriersListData([]);
      // }
    };
    const liCarrierNameOnclick = async(item)=>{
      setCarriersListData([]);
      setUser(item)
    }
    
    /******END *******/

  const sendData = async (e) => {
    e.preventDefault();
    setSubmiteed(true);
    await axios.post(
          addInvoices,
          {
            carrier_id : (user.id)?user.id:'',
            companyName: user.companyName,
            invoice_no: user.invoice_no,
            nscNo: user.nscNo,
            mcNo: user.mcNo,
            dotNo: user.dotNo,
            dispatcher: user.dispatcher,
            address: user.address,
            state: user.state,
            city: user.city,
            zipcode: user.zipcode,
            phoneNo: user.phoneNo,
            email: user.email,
            payment_mode: user.payment_mode,
            amount: user.amount,
            status: status, 
            picks: picks, 
            drops: drops, 
            equipment:user?.equipment??'',
            size:user?.size??'',
            pieces:user?.pieces??'',
            commodity:user?.commodity??'',
            miles:user?.miles??'',
            weight:user?.weight??'',
            min:user?.min??'',
            max:user?.max??'',
            files:files
          },
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          // console.log(res.status);
          if (res.status === 200) {
            console.log("Form submitted successfully");
            toast.success("Form submitted successfully!", {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            const delayfrfresh = () => {
              navigate("/admin/invoices");
            };
            // eslint-disable-next-line
            const delayRef = setTimeout(delayfrfresh, 3100);
          }
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            err.response?.data?.error.map((e)=>{
            toast.error(e, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
           
          })
        }else{
          toast.error('Somthing went worng, Kindly refresh the page and try Again', {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
         
        }
        const delay = () => {
          setSubmiteed(false);
        };
        // eslint-disable-next-line
        const myTimeout = setTimeout(delay, 3000);
      });
    
  };

  
  
  const tork = false;
  const [userPlace, setUserPlace] = useState({
    companyName: "Enter Invoice Company Name",
    nscNo: "Enter Invoice NSC No",
    mcNo: "Enter Invoice MC No",
    dotNo: "Enter Invoice DOT No",
    dispatcher: "Enter Dispatcher Name",
    address: "Enter Invoice Address",
    state: "Enter Invoice state",
    city: "Enter Invoice city",
    zipcode: "Enter Invoice zipcode",
    phoneNo: "Type Invoice Phone",
    email: "Enter Invoice Email",
    amount: "Enter Invoice Amount",
  });

  if (tork === true) {
    setUserPlace(null);
  }
  const heandleChangeState = (e,i)=>{
    const newState = picks.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.id === i) {
        return {...obj, value: e.target.value};
      }

      // 👇️ otherwise return the object as is
      return obj;
    });

    setPicks(newState);
  }
  const heandleChangeStateDrop = (e,i)=>{
    const newState = drops.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.id === i) {
        return {...obj, value: e.target.value};
      }

      // 👇️ otherwise return the object as is
      return obj;
    });

    setDrops(newState);
  }

  const handleDeleteInvoiceImage1 = (image)=>{
    axios.post(deleteInvoiceImage1,{
      image: image,
     },
     {
       withCredentials: true,
     }
   )
   .then((res) => {
     let newdata = [];
     files.forEach(e=>{
       if(image != e.key){
         newdata.push(e);

       }
       setFiles(newdata);
     })
     
   })
   .catch((err) => {

   });
 }

  return (
    <>
      <div className="mt-7  mb-2 rounded-lg bg-white shadow-xl dark:bg-navy-800 ">
        <div className="mx-auto w-[90%]  ">
          <form action="" method="POST" className="">
            <div className="">
              <div className="field w-[100%] ">
                <div className="input  relative">
                  <label htmlFor="InvoiceNo">Invoice No</label>
                  <input
                    type="text"
                    placeholder="Invoice No"
                    maxLength={80}
                    name="invoice_no"
                    value={user.invoice_no}
                    onChange={handleInput}  
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="companyName"> Company Name</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.companyName}`}
                    maxLength={80}
                    name="companyName"
                    value={user.companyName}
                    onChange={handleInput}
                    onKeyUp={handleKeyUp}
                    autocomplete="off"
                  />
                </div>
                <div id="carrierDeails" style={{display: cdata ? 'block' : 'none' }}></div>
                <div>
                  <ul className="items-list" >
                  {carriersListData.length > 0 &&
                    carriersListData.map((item, index) => {
                      return <li onClick={() => liCarrierNameOnclick(item)} key = {index}>{item.companyName}<hr/></li>
                    })}
                    </ul>
                  </div>
               
                <div className="input relative">
                  <label htmlFor="nscNo" className="">
                    NSC Number
                  </label>
                  <input
                    type="text"
                    placeholder={`${userPlace.nscNo}`}
                    name="nscNo"
                    value={user.nscNo}
                    onChange={handleInput}
                  />
                </div>

                <div className="input relative">
                  <label htmlFor="mcNo"> MC Number</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.mcNo}`}
                    name="mcNo"
                    value={user.mcNo}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative ">
                  <label htmlFor="dotNo"> DOT Number</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.dotNo}`}
                    name="dotNo"
                    value={user.dotNo}
                    onChange={handleInput}
                  />
                </div>

              </div>

             
              <div className="field">
                <div className="input relative ">
                  <label htmlFor="dispatcher"> Dispatcher Name</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.dispatcher}`}
                    name="dispatcher"
                    value={user.dispatcher}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.address}`}
                    maxLength={80}
                    name="address"
                    value={user.address}
                    onChange={handleInput}
                  />
                </div>
                
              </div>
             
              <div className="field">
                <div className="input relative">
                    <label htmlFor="city"> City</label>
                    <input
                      type="text"
                      placeholder={`${userPlace.city}`}
                      name="city"
                      maxLength={80}
                      value={user.city}
                      onChange={handleInput}
                    />
                  </div>
                <div className="input  relative ">
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.state}`}
                    name="state"
                    value={user.state}
                    onChange={handleInput}
                  />
                </div>

                
                <div className="input relative">
                  <label htmlFor="zipcode">Zipcode</label>
                  <input
                    className="h-[53px] overflow-hidden !rounded-md   "
                    id="zipcode"
                    type="text"
                    value={user.zipcode}
                    onChange={handleInput}
                    name="zipcode"
                  />
                </div>

                <div className="input  relative">
                  <label htmlFor="phoneNo">Phone Number</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.phoneNo}`}
                    maxLength={80}
                    name="phoneNo"
                    value={user.phoneNo}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="field">
              
                <div className="input  relative">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    placeholder={`${userPlace.email}`}
                    maxLength={80}
                    name="email"
                    value={user.email}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="number"
                    placeholder={`${userPlace.amount}`}
                    maxLength={80}
                    name="amount"
                    value={user.amount}
                    onChange={handleInput}
                  />
                </div>
                <div className="input relative">
                  <label htmlFor="payment_mode">Payment Mode</label>
                  
                  <select name="payment_mode" id="payment_mode" value={user.payment_mode} onChange={handleInput}>
                    <option value="USD">USD</option>
                    <option value="CAD">CAD</option>
                  </select>
                  
                </div>
                <div className="input relative">
                  <label htmlFor="status-select">Status:</label>
                  <select
                    id="status-select"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="1">Active</option>
                    <option value="0">Disabled</option>
                  </select>
                </div>
                
              </div>
              
              <Attachments fileNames={setFiles} setSubmiteed={setSubmiteed} />
              {files.map((item,i)=>{
                    return(
                      <>
                      <div className="inline-grid grid-cols-2 gap-4" > 
                      <a href={item?.location} target="_blank" ><img src="/pdf.png" width="100px" /></a>
                      <a href="javascript:void(0)" onClick={()=>{handleDeleteInvoiceImage1(item?.key)}}><svg class="h-8 w-8 text-red-600"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="18" y1="6" x2="6" y2="18" />  <line x1="6" y1="6" x2="18" y2="18" /></svg></a>
                      
                      </div>
                      </>
                    )
                    })}
              <hr />
              <br />
              <h2 style={{fontSize:'20px'}}>Load Info</h2>
              <div className="field">
             
                <div className="input  relative">
                  <label htmlFor="email">Equipment</label>
                  <input
                    type="text"
                    placeholder="Equipment"
                    maxLength={80}
                    name="equipment"
                    value={user.equipment}
                    onChange={handleInput}
                  />
                </div>
                
                <div className="input  relative">
                  <label htmlFor="email">Size</label>
                  <input
                    type="text"
                    placeholder="Equipment"
                    maxLength={80}
                    name="size"
                    value={user.size}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="email">Pieces</label>
                  <input
                    type="text"
                    placeholder="Pieces"
                    maxLength={80}
                    name="pieces"
                    value={user.pieces}
                    onChange={handleInput}
                  />
                </div>

                <div className="input  relative">
                  <label htmlFor="email">Commodity</label>
                  <input
                    type="text"
                    placeholder="Commodity"
                    maxLength={80}
                    name="commodity"
                    value={user.commodity}
                    onChange={handleInput}
                  />
                </div>

              </div>


              <div className="field">
             
               
                
                <div className="input  relative">
                  <label htmlFor="email">Miles</label>
                  <input
                    type="text"
                    placeholder="Miles"
                    maxLength={80}
                    name="miles"
                    value={user.miles}
                    onChange={handleInput}
                  />
                </div>
                <div className="input  relative">
                  <label htmlFor="email">Weight</label>
                  <input
                    type="text"
                    placeholder="Weight"
                    maxLength={80}
                    name="weight"
                    value={user.weight}
                    onChange={handleInput}
                  />
                </div>

                <div className="input  relative">
                  <label htmlFor="email">Min </label>
                  <input
                    type="text"
                    placeholder="Min"
                    maxLength={80}
                    name="min"
                    value={user.min}
                    onChange={handleInput}
                  />
                </div>
                
                <div className="input  relative">
                  <label htmlFor="email">Max</label>
                  <input
                    type="text"
                    placeholder="Max"
                    maxLength={80}
                    name="max"
                    value={user.max}
                    onChange={handleInput}
                  />
                </div>
              </div>


              {/* *********************************************************************** */}
              
              <div className="field">
                <div className="input relative">
                  <label htmlFor="status-select">Picks</label>
                    {picks.map((item,i)=>{
                      return(
                        <>
                        <textarea key={i}  value={item.value} rows="4" onChange={(e)=> { heandleChangeState(e,item.id)}} className="input_spacer"/>
                        </>
                      )
                      })}
                      <div className="picks-btn-group" role="group">
                        <button type="button"  className="add_more_btn" onClick={(e)=>{ setPicks(oldArray=>[...oldArray, {id: picks.length,  value: 'Name: \nAddress: \nCity: \nProvince:'},]);   }}>+</button>
                        <button type="button" className="remove_btn" onClick={(e)=>{ setPicks((previousArr) => (previousArr.slice(0, -1)));   }}>-</button>
                      </div>
                      
                
                </div>
                
                
                <div className="input relative">
                  <label htmlFor="payment_mode">Drops</label>
                  {drops.map((item,i)=>{
                    return(
                      <>
                      <textarea key={i}  value={item.value} rows="4" onChange={(e)=> { heandleChangeStateDrop(e,item.id)}} children className="input_spacer"/>
                      </>
                    )
                    })}
                  <div className="picks-btn-group">
                    <input type="button" className="add_more_btn" onClick={(e)=>{  setDrops(oldArray=>[...oldArray, {id: drops.length,  value: 'Name: \nAddress: \nCity: \nProvince:'},]);  }} value="+"/>
                    <input type="button" className="remove_btn" onClick={(e)=>{  setDrops((previousArr) => (previousArr.slice(0, -1)));  }} value="-"/>
                  </div>
                </div>

              </div>
               
{/* ******************************************************************************* */}




              <div className="relative mx-2">
                {/* <div className="field cursor-pointer" variant="primary" onClick={handleShow}>
                  <div className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
                    Add pick/drop
                  </div>
                </div> */}

                

                
              </div>
              {submiteed ? (
                <div className="field cursor-pointer ">
                  <div className=" my-5 rounded-md bg-gray-300 py-3 px-12 text-white">
                    <button disabled>Loading....</button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="field cursor-pointer " onClick={sendData}>
                    <div className=" my-5 rounded-md bg-primaryBlue py-3 px-12 text-white">
                      <button>Submit</button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      
      <ToastContainer />
    </>
  );
};

export default InvoicesForm;
