import { useState, useEffect } from "react";
import "./carriers.css";
// import { carriersDataTable } from "views/admin/default/variables/columnsData";
// import tableDataComplex from "../default/variables/tableDataComplex.json";
// import ComplexTable from "views/admin/default/components/ComplexTable";
import { getAllCarrier } from "proxyUrl";
import CarrierTable from "./CarrierTable";
// import { GetBookingDataByUserId } from "proxyUrl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
const Carriers = () => {
  const [carrierData, setCarrierData] = useState("");

  // console.log(user)
  const currentUser = useSelector((state) => state.user.user.userRole.name);
  console.log(currentUser);
  const fetchData = () => {
    axios
      .get(getAllCarrier, {
        withCredentials: true,
      })
      .then((res) => {
        return res;
      })
      .then((carrierdata) => {
        const carrierData = carrierdata.data;
        setCarrierData(carrierData);
      });
  };
  useEffect(() => {
    fetchData();
    // const userData = JSON.parse(localStorage.getItem("user"));
    // setUserItem(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(user.length);
  return (
    <>
      <div className=" add-booking-header  relative  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 shadow-lg dark:bg-navy-800">
        <div className="text-xl font-bold text-white  dark:text-white">
          All Carrier
        </div>
        <Link to="/admin/carriers/add">
          <button className="rounded-full bg-white p-1  px-3 text-navy-800 dark:bg-navy-900 dark:text-white">
            Add Carrier
          </button>
        </Link>
      </div>
      <div className="relative -mt-7  grid w-full grid-cols-1 gap-5 md:grid-cols-1">
        {/* <TotalSpent /> */}
        {/* <WeeklyRevenue /> */}
        {carrierData ? (
          <>
          <CarrierTable
            columnLenghth={carrierData.length}
            tableData={carrierData}
            editRoute={`/admin/carriers/edit`}
            deleteRoute={`/admin/carriers/delete`}
            generateInvoice={`/admin/carriers/carrier_invoice`}
          />
        </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Carriers;
