import { useState, useEffect } from "react";
import "./invoices.css";

import { getAllInvoice } from "proxyUrl";
import InvoiceTable from "./InvoiceTable";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
const Invoices = () => {
  const [invoiceData, setInvoiceData] = useState("");

  const currentUser = useSelector((state) => state.user.user.userRole.name);
  console.log(currentUser);
  const fetchData = () => {
    axios
      .get(getAllInvoice, {
        withCredentials: true,
      })
      .then((res) => {
        return res;
      })
      .then((invoicedata) => {
        const invoiceData = invoicedata.data;
        setInvoiceData(invoiceData);
      });
  };
  useEffect(() => {
    fetchData();
    // const userData = JSON.parse(localStorage.getItem("user"));
    // setUserItem(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(user.length);
  return (
    <>
      <div className=" add-booking-header  relative  mx-4 mt-6 flex justify-between rounded-2xl bg-primaryBlue p-3 shadow-lg dark:bg-navy-800">
        <div className="text-xl font-bold text-white  dark:text-white">
          All Invoice
        </div>
        <Link to="/admin/invoices/add">
          <button className="rounded-full bg-white p-1  px-3 text-navy-800 dark:bg-navy-900 dark:text-white">
            Add Invoice
          </button>
        </Link>
      </div>
      <div className="relative -mt-7  grid w-full grid-cols-1 gap-5 md:grid-cols-1">
        {/* <TotalSpent /> */}
        {/* <WeeklyRevenue /> */}
        {invoiceData ? (
          <>
          <InvoiceTable
            columnLenghth={invoiceData.length}
            tableData={invoiceData}
            setInvoiceData={setInvoiceData}
            editRoute={`/admin/invoices/edit`}
            deleteRoute={`/admin/invoices/delete`}
            generateInvoice={`/admin/invoices/carrier_invoice`}
            mangePicksAndDrops={`/admin/invoices/managepicks`}
          />
        </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Invoices;
